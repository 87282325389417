.landing {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 0 10%;
    overflow: hidden;
}

.landing .partiOne {
    margin-top: 50px;
}

.landing .partiTwo {
    display: flex;
}

.landing .text {
    width: 50%;
}

.landing .topLine {
    display: block;
    position: relative;
    font-size: 35px;
    letter-spacing: 5px;
    color: #3d535f;
}

.landing .topLine span {
    color: #3d535f;
    font-size: 35px;
}

.landing .topLine::after {
    position: absolute;
    content: "";
    height: 4px;
    width: 45px;
    bottom: 10px;
    background-color: rgb(0, 166, 255);
    left: 144px;
}

.landing h1 {
    display: block;
    font-size: 4em;
    font-weight: 900;
    color: #3d535f;
}

.landing span {
    color: rgb(0, 166, 255);
    text-transform: capitalize;
}

.landing P {
    display: block;
    width: 90%;
    font-size: 1.2em;
    color: #3d535f;
}

.landing .btn {
    width: 100%;
    position: relative;
    left: 250px;
}

.landing button {
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    background-color: #3d535f;
    padding: 8px 14px;
    margin: 40px 5px;
    letter-spacing: 2px;
    text-transform: Capitalize;
    box-shadow: 0 15px 18px rgba(0, 0, 0, 0.4);
}

button:hover {
    background-color: rgb(0, 166, 255);
}

.landing .image {
    position: relative;
    width: 50%;
}

.landing img {
    width: 100%;
    height: 100%;
}

.landing .me {
    position: relative;
    top: 624px;
    z-index: 3;
    animation: move-Admin 4s linear infinite;
}

@keyframes move-Admin {
    0% {
        top: -269px;
        right: 0;
    }
    50% {
        top: -259px;
        right: 20px;
    }
    100% {
        top: -269px;
        right: 0;
    }
}

.blue {
    position: relative;
    z-index: 2;
    bottom: 1028px;
    animation: move-blue 4s linear infinite;
}

@keyframes move-blue {
    0% {
        bottom: 1028px;
        right: 0;
    }
    50% {
        bottom: 1018px;
        right: 10px;
    }
    100% {
        bottom: 1028px;
        right: 0;
    }
}

.cercel {
    position: relative;
    right: 753px;
    top: -1505px;
    z-index: -1;
    width: 35%;
    height: 11%;
    animation: move-cercel 4s linear infinite;
}

@keyframes move-cercel {
    50% {
        right: 743px;
        top: -1496px;
    }
}

.rombow {
    position: relative;
    bottom: 1523px;
    right: 400px;
    z-index: -3;
    width: 50%;
    height: 15%;
    animation: move-rombow 4s linear infinite;
}

@keyframes move-rombow {
    50% {
        bottom: 1513px;
    }
}

@media(max-width:767px) {
    .landing {
        width: 100%;
        height: 100%;
        padding: 10px 10%;
        margin: 10px 0px;
    }
    .landing .partiOne {
        margin-top: 10px;
    }
    .landing .text {
        width: 100%;
    }
    .landing .topLine {
        font-size: 20px;
        letter-spacing: 2px;
    }
    .landing .titleH {
        font-size: 25px;
    }
    .landing .topLine::after {
        width: 30px;
        bottom: 6px;
        left: 115px;
    }
    .landing h1 {
        font-size: 2em;
        font-weight: 650;
    }
    .landing P {
        display: block;
        width: 90%;
        font-size: 16px;
    }
    .landing button {
        font-size: 18px;
    }
    .landing .btn {
        width: 100%;
        left: -11px;
        top: -37px;
        margin-top: 5px;
    }
    @keyframes move-Admin {
        0% {
            top: -64px;
            right: 80px;
        }
        50% {
            top: -54px;
            right: 60px;
        }
        100% {
            top: -64px;
            right: 80px;
        }
    }
    .landing .me {
        position: relative;
        top: 0;
        right: 88px;
        z-index: -2;
        width: 231%;
        animation: move-Admin 6s linear infinite;
    }
    .blue {
        position: relative;
        z-index: -1;
        top: -155px;
        left: -234px;
    }
    @keyframes move-cercel {
        50% {
            right: 190px;
            top: -456px;
        }
    }
    .cercel {
        position: relative;
        top: -487px;
        right: 219px;
        z-index: -5;
        width: 65%;
        height: 15%;
        animation: move-cercel 6s linear infinite;
    }
    .rombow {
        display: none;
    }
}