.projects .partiOne {
    text-align: center;
    margin: 100px 0;
}

.projects .partiOne h2 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.projects .partiOne p {
    font-size: 1.2em;
    letter-spacing: 1px;
    width: 100%;
}

.tabTwo,
.tabThree {
    display: grid;
    grid-template-columns: repeat(3, 450px);
    gap: 50px;
}

.projects .partiTwo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../images/background-one.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.projects .partiTwo img {
    border-radius: 20px;
    width: 100%;
}

.projects .partiOne .tabsButton {
    width: 80%;
    margin: 50px auto;
}

.projects .partiOne .tabsButton .left:hover::before,
.projects .partiOne .tabsButton .left {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}

.projects .partiOne .tabsButton .right,
.projects .partiOne .tabsButton .right:hover::before {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}

.projects .partiOne .tabsButton button {
    position: relative;
    outline: none;
    width: 33%;
    color: white;
    background-color: black;
    border: none;
    cursor: pointer;
    box-shadow: 0 15px 18px rgb(0 0 0 / 40%);
    letter-spacing: 2px;
    padding: 8px 14px;
    font-size: 25px;
    z-index: 0;
}

.projects .partiOne .tabsButton button::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, rgb(0, 166, 255) 5.91%, #BC70A4 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.projects .partiOne .tabsButton button:hover::before {
    width: 100% !important;
}

.projects .partiOne .center {
    border-left: solid white 1px !important;
    border-right: solid white 1px !important;
}

.tabOne p {
    text-align: center;
    width: 40%;
    margin: 10px auto;
}

.tabTwo .image,
.tabThree .image {
    position: relative;
    cursor: pointer;
    width: fit-content;
}

.tabTwo .image::before,
.tabThree .image::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0%;
    background-image: linear-gradient(90.21deg, rgb(0, 166, 255) 5.91%, #BC70A4 111.58%);
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    border-radius: 20px;
    opacity: 0.85;
}

.tabTwo .image:hover::before,
.tabThree .image:hover::before {
    height: 100%;
}

@media (max-width:767px) {
    .projects .partiOne .tabsButton button {
        font-size: 15px;
    }
    .tabOne p {
        width: 66%;
        margin: 1px auto;
    }
    .tabTwo,
    .tabThree {
        display: grid;
        grid-template-columns: repeat(1, 300px);
        gap: 10px;
    }
    .projects .partiOne {
        text-align: center;
        margin: 80px 0 10px 0;
    }
}